
.profile-image-container {
    position:relative;
    width:200px;
    height:200px;
    border-radius: 50%;
    img {
    width:100%;
    height: 100%;
    border-radius: 50%;
    vertical-align:top;
    }
    &:after{
      content:'\A';
      position:absolute;
      width:100%; height:100%;
      border-radius: 50%;
      top:0; left:0;
      background:rgba(0,0,0,0.6);
      opacity:0;
      transition: all 0.5s;
      -webkit-transition: all 0.5s;
    }
    &:hover{
      &:after{
        opacity:1;
      }
    }
   
    .crop-btn{
      position: absolute;
      top:50%;
      left: 50%;
      margin-top: -15px;
      margin-left: -15px;
      width: 30px;
      height: 30px;
      z-index: 10;
      &__icon {
      width: 100%;
      height: 100%;
      fill: #FFF !important;
      }
    }
    
  }
  
.profile-image-container-no-hover {
    position:relative;
    width:200px;
    height:200px;
    border-radius: 50%;
    img {
    width:100%;
    height: 100%;
    border-radius: 50%;
    vertical-align:top;
    }
  }
  .modal-transparent-fit {
    width: fit-content;
    margin: 0 auto;
    .modal-content {
      background: none;
    }
  }
  .crop-btns-container {
  
    position: absolute;
    bottom: -55px;
    left: 50%;
    margin-left: -70px;
    height: 50px;
    width: 140px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    :hover{
      transition: all 0.1s;
      -webkit-transition: all 0.1s;
      transform: scale(1.1);
    }
  }
  .checkbox-btn {
    border-radius: 50%;
    background: $gradient-green;
    -webkit-box-shadow:  0 4px 20px 0 rgba(0,0,0,.57), 0 7px 10px -5px rgba(76,175,80,.4);
    -moz-box-shadow:   0 4px 20px 0 rgba(0,0,0,.57), 0 7px 10px -5px rgba(76,175,80,.4);
    box-shadow:   0 4px 20px 0 rgba(0,0,0,.57), 0 7px 10px -5px rgba(76,175,80,.4);
    border: 1px solid #43a047;
    text-align: center;
    width: 60px;
    height: 60px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &-red {
      background: $gradient-red;
      border: 1px solid #e53935;
      -webkit-box-shadow:  0 4px 20px 0 rgba(0,0,0,.57), 0 7px 10px -5px rgba(244,67,54,.4);
      -moz-box-shadow:   0 4px 20px 0 rgba(0,0,0,.57), 0 7px 10px -5px rgba(244,67,54,.4);
      box-shadow:   0 4px 20px 0 rgba(0,0,0,.57), 0 7px 10px -5px rgba(244,67,54,.4);
    }
    i {
      font-size: 30px;
      line-height: 0.5;
      vertical-align: text-bottom;
      color: #FFF !important;
  
    }
  }