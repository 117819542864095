.react-autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.icon-input {
  @extend %flex-align-center;
  border: $input-group-border;
  border-radius: 0.25rem;

  &:not(:last-child) {
    margin-bottom: $x-small-margin;
  }

  &:focus-within {
    border-color: $color-logo-1;
    i {
      color: $color-logo-1;
    }
  }

  .input-group-append {
    padding: $x-small-margin;
    border-right: $input-group-border;
  }

  .form-control {
    &,
    &:focus {
      border-color: #fff;
      box-shadow: none;
      outline: 0 none;
    }
  }
}

.input__error {
  color: $color-error;
  margin-bottom: $x-small-margin;
  width: 100%;
}

.upload-btn {
  align-self: flex-end;
  position: absolute;
  margin-top: 20px !important;
  background: $color-grey-light;
  color: $green-link;
  width: 4rem;
  height: 4rem;
  border: none;
  box-shadow: 0 2px 0 $color-grey-light-2;
  // right: 25%;

  &:hover:not(.btn-enabled) {
    background: $color-grey-light-2;
    box-shadow: 0 1px 0 $color-grey-light-2;
  }

  &:active {
    background: $color-grey-light-3;
    box-shadow: 0 1px 0 $color-grey-light-2;
  }

  &.btn-enabled {
    cursor: not-allowed;
  }
  &:disabled {
    opacity: unset;
  }
}

.contract {
  cursor: not-allowed;
}

.limit {
  float: right;
  max-width: 100px !important;
}

.input-container {
  width: 100%;

  .label {
    margin-left: 38px;
  }
}
.invalid-file {
  margin-top: 1.8rem;
  font-size: 80%;
  color: #dc3545;
}
