.logo {
  background: transparent;
  text-align: center;
  width: $side-bar-width;
  height: $nav-bar-height;
  top: 0;
  left: 0;
  z-index: 10;
  position: fixed;
  width: $side-bar-width;
  background-color: #eff1f5;
  display: flex;
  justify-content: center;
  align-items: center;
  &__img {
    height: 100%;
  }
  &__loader {
    border-top-right-radius: 0px;
    background-color: unset;
  }
  &__withpadding {
    padding: 20px;
  }
}
