.notifications {
  position: relative;

  &__container {
    position: absolute;
    top: 150%;
    right: 30%;
    background-color: #fff;
    width: 30rem;
  }
  &__titleContainer {
    border-bottom: $border-bottom-grey;
  }

  &__title {
    color: $color-primary-2;
    font-weight: 550;
    padding: 0.5rem;
    text-align: left;
  }
  &__right {
    color: $color-link;
    padding: 0.5rem;
    text-align: right;
    text-decoration: underline;
    font-size: 10px;
  }

  &__list {
    @extend %scrollable;
    max-height: 65vh;
    overflow-y: scroll !important;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar {
      width: 12px;
    }
  }

  &__footer {
    @extend %flex-center;
    border-top: $border-bottom-grey;
    padding: 0.5rem;
  }

  &__marker,
  &__container {
    box-shadow: $shadow-light-5;
  }

  &__marker {
    position: absolute;
    background-color: $background-color;
    height: 1rem;
    width: 2rem;
    --webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    top: 100%;
    right: 1.7rem;
  }

  &__item {
    padding: $tiny-margin;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    border-bottom: $border-bottom-grey;

    &--not-looked {
      background-color: $color-grey-light;
    }
    &:hover {
      background-color: #f1aa26;
    }
  }

  &__message {
    font-size: 0.9rem;
    padding: 0.4rem;
  }

  &__date {
    align-self: flex-end;
    font-size: 0.6rem;
    color: $color-grey-light-2;
    text-align: right;
  }
}

//from store board

$a: 100%;
.nav-bar {
  @extend %flex-align-center;
  justify-content: space-between;
  height: $nav-bar-height;
  background-color: #fff;
  top: 0;
  position: fixed;
  left: $side-bar-width;
  width: calc(#{$a} - #{$side-bar-width});
  box-shadow: $shadow-light-1;
  z-index: 4;

  &__title {
    margin-left: $small-margin;
  }

  &__menu {
    @extend %small-padding-horizontal;
    @extend %flex-align-center;
  }

  &__item {
    margin-right: $small-margin;
    position: relative;
    .fa {
      color: $color-grey-light-2;
      font-size: $font-size-small;
    }
  }

  &__news {
    top: -1rem;
    left: 0.5rem;
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    text-align: center;
    border-radius: 50%;
    background-color: $color-error;
    color: #fff;
  }
}
