.input-range {
  height: 1.2rem !important;

  &__slider {
    height: 1.2rem !important;
    width: 1.2rem !important;
    background: #fff !important;
    border: 2.5px solid #3f51b5 !important;
  }

  &--disabled {
    .input-range__slider {
      background: #eeeeee !important;
      border-width: 0px !important;
    }
  }
}
