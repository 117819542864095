@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,600,700,900);
* {
  padding: 0;
  margin: 0; }

*,
*::before,
*::before {
  box-sizing: inherit; }

html {
  box-sizing: border-box;
  font-size: 80%; }

body {
  font-family: "Ubuntu";
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.01em;
  background-color: #f7f7f7; }

h5 {
  font-size: 16px;
  font-weight: 600; }

.font-weight-bold {
  font-weight: 900 !important; }

.fa-2x {
  font-size: 16px; }

.badge {
  font-size: 100%; }

.font-size-larger {
  font-size: 24px; }

.font-weight-700 {
  font-weight: 700; }

a,
a:hover {
  text-decoration: none;
  cursor: pointer;
  color: #000; }

.private-container {
  display: -webkit-flex;
  display: flex;
  min-height: 100vh; }

.main {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: 19rem;
  margin-top: 5rem; }

.container-fluid {
  padding: 2rem;
  min-height: 100%;
  overflow-y: auto; }

.public-container {
  min-height: 100vh;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto; }

ul,
li {
  list-style: none; }

button, button:visited {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none; }

#store-bo {
  min-height: 100vh;
  min-width: 100%;
  border: unset; }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield; }

.medium-photo, .user-details__photo, .user-details__photo-container {
  height: 3rem;
  width: 3rem;
  border-radius: 50%; }

.labels {
  color: red !important; }

.category-photo {
  height: 4rem;
  width: 4rem;
  border-radius: 0%; }

.alert, .nav-bar, .nav-bar__menu, .user-details, .side-bar__block, .side-bar__link, .side-bar-collapsible__block, .side-bar-collapsible__link, .search-bar, .search-bar .search-bar__btn.btn, .nav-tabs .tab, .icon-input, .modal__footer, .list-group__item, .list-item__container, .menu-category,
.sub-category,
.menu-item, .menu-item__label, .menu-item__index {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.public-container, .user-details__photo-container, .card-body__center, .graphql-result__container, .modal__header, .search, .notifications__footer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.medium-padding-horizontal {
  padding: 0 4rem; }

.small-padding {
  padding: 2rem 0; }

.small-padding, .small-padding-horizontal, .nav-bar__menu {
  padding: 0 2rem; }

.medium-photo, .user-details__photo, .user-details__photo-container {
  height: 3rem;
  width: 3rem; }

.large-photo {
  height: 5rem;
  width: 5rem; }

.x-large-photo {
  height: 9rem;
  width: 9rem; }

.small-photo, .img-profile {
  height: 2rem;
  width: 2rem; }

.side-bar__icon, .side-bar-collapsible__icon {
  height: 1.5rem;
  width: 1.5rem; }

.public-container {
  background: url(/images/publicbg.jpg) no-repeat center center fixed;
  background-size: cover; }

.scrollable-table tbody, .side-bar__list, .menus, .notifications__list {
  overflow-y: visible;
  overflow-x: auto; }
  .scrollable-table tbody::-webkit-scrollbar, .side-bar__list::-webkit-scrollbar, .menus::-webkit-scrollbar, .notifications__list::-webkit-scrollbar {
    width: 0.5rem;
    background-color: #fff; }
  .scrollable-table tbody::-webkit-scrollbar-track, .side-bar__list::-webkit-scrollbar-track, .menus::-webkit-scrollbar-track, .notifications__list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6rem rgba(0, 0, 0, 0.3);
    border-radius: 0.4rem;
    background-color: #fff; }
  .scrollable-table tbody::-webkit-scrollbar-thumb, .side-bar__list::-webkit-scrollbar-thumb, .menus::-webkit-scrollbar-thumb, .notifications__list::-webkit-scrollbar-thumb {
    border-radius: 0.4rem;
    box-shadow: inset 0 0 6rem rgba(0, 0, 0, 0.3);
    background-color: linear-gradient(to left, #71c476, #4cab51); }

.scrollable-table tbody {
  display: block;
  max-height: 600px;
  overflow-y: auto; }

.scrollable-table thead,
.scrollable-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed; }

.breadcrumb .breadcrumb-item a {
  color: #5049d6 !important; }

.breadcrumb .breadcrumb-item a {
  color: #28a745 !important; }

.link {
  color: #28a745 !important; }

.link-DRIVE {
  color: #fb8c00 !important; }

.link-EXPRESS {
  color: #28a745 !important; }

.cursor-pointer {
  cursor: pointer !important; }

.cursor-disabled {
  cursor: not-allowed !important; }

.line-height-1 {
  line-height: 1; }

.icon {
  font-size: 1.6rem;
  font-weight: bold; }
  .icon--danger {
    color: #dc3545; }
  .icon--success {
    color: #71c476; }
  .icon--warning {
    color: #fb8c00; }

.badgeClass {
  background-color: #eeeeee;
  font-weight: 550;
  color: currentColor;
  width: 8rem;
  padding: 0.6rem;
  margin-right: 1rem; }

.img-piece {
  height: 3rem;
  width: 3rem; }

.round {
  border-radius: 50%; }

.border-img {
  border: 2px solid #dee2e6 !important; }

.danger {
  background-color: #dc3545; }

.purple {
  background-color: #7e83d1; }

.info {
  background-color: aqua; }

.success {
  background: linear-gradient(60deg, #66bb6a, #43a047); }

.gradient-orange {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4);
  background: linear-gradient(60deg, #ffa726, #fb8c00); }

.gradient-green {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4);
  background: linear-gradient(60deg, #66bb6a, #43a047); }

.gradient-red {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(244, 67, 54, 0.4);
  background: linear-gradient(60deg, #ef5350, #e53935); }

.gradient-blue {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 188, 212, 0.4);
  background: linear-gradient(60deg, #26c6da, #00acc1); }

.total-container {
  border-radius: 3px !important;
  width: 5.5rem;
  margin-top: -2.5rem;
  margin-left: 0.3rem;
  padding: 2rem; }
  .total-container--danger {
    background: #dc3545; }

.card-header {
  border-radius: 3px !important;
  color: #fff;
  width: 90%;
  margin-left: 2rem;
  margin-top: -1.5rem;
  height: 5rem; }
  .card-header--danger {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(244, 67, 54, 0.4);
    background: linear-gradient(60deg, #ef5350, #e53935); }
  .card-header--success {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4);
    background: linear-gradient(60deg, #66bb6a, #43a047); }
  .card-header--warning {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4);
    background: linear-gradient(60deg, #ffa726, #fb8c00); }
  .card-header--purple {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 188, 212, 0.4);
    background: linear-gradient(60deg, #26c6da, #00acc1); }

.home-icon {
  margin-right: 1rem;
  fill: #000; }

.home-margin {
  margin-top: 3rem; }

.bg-primary {
  background: linear-gradient(to left, #71c476, #4cab51); }

.flex-1 {
  -webkit-flex: 1 1;
          flex: 1 1; }

.flex-2 {
  -webkit-flex: 2 1;
          flex: 2 1; }

.notif {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.bold {
  font-weight: 600; }

.padding_extra {
  padding: 0.75rem 1.25rem; }

img.padding_extra {
  padding: 1.75rem 1.25rem; }

.text_input {
  display: inline-block;
  min-width: 107px; }

.no-margin {
  margin: 0 !important; }

.font-size-x-small {
  font-size: 0.9rem; }

.gray-text {
  color: #86898b;
  text-align: center; }

.extrabold {
  font-weight: 900; }

.btn-secondary {
  background-color: #b5b5b5;
  border-color: #b5b5b5; }

@keyframes side-bar-animation {
  0% {
    border-left: 0.01rem solid #fff; }
  25% {
    border-left: 0.05rem solid #fff; }
  50% {
    border-left: 0.1rem solid #fff; }
  75% {
    border-left: 0.2rem solid #fff; }
  100% {
    border-left: 0.3rem solid #fff; } }

@-webkit-keyframes side-bar-animation {
  0% {
    border-left: 0.01rem solid #fff; }
  25% {
    border-left: 0.05rem solid #fff; }
  50% {
    border-left: 0.1rem solid #fff; }
  75% {
    border-left: 0.2rem solid #fff; }
  100% {
    border-left: 0.3rem solid #fff; } }

@-webkit-keyframes alertAnimation {
  0% {
    -webkit-transform: translateY(-2rem);
            transform: translateY(-2rem); }
  30% {
    -webkit-transform: translateY(-2rem);
            transform: translateY(-2rem); }
  60% {
    -webkit-transform: translateY(-1.5rem);
            transform: translateY(-1.5rem); }
  90% {
    -webkit-transform: translateY(1rem);
            transform: translateY(1rem); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes alertAnimation {
  0% {
    -webkit-transform: translateY(-2rem);
            transform: translateY(-2rem); }
  30% {
    -webkit-transform: translateY(-2rem);
            transform: translateY(-2rem); }
  60% {
    -webkit-transform: translateY(-1.5rem);
            transform: translateY(-1.5rem); }
  90% {
    -webkit-transform: translateY(1rem);
            transform: translateY(1rem); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.rotate90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  transition: all 0.4s ease 0s; }

.logo {
  background: transparent;
  text-align: center;
  width: 19rem;
  height: 5rem;
  top: 0;
  left: 0;
  z-index: 10;
  position: fixed;
  width: 19rem;
  background-color: #eff1f5;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  .logo__img {
    height: 100%; }
  .logo__loader {
    border-top-right-radius: 0px;
    background-color: unset; }
  .logo__withpadding {
    padding: 20px; }

.alert {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  max-height: 5rem;
  width: 100%;
  padding: 2rem;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 400;
  margin-bottom: 1rem;
  -webkit-animation: alertAnimation 0.2s ease-in;
          animation: alertAnimation 0.2s ease-in;
  --webkit-animation: alertAnimation 0.2s ease-in;
  border-radius: 0; }
  .alert__message {
    -webkit-flex: 1 1;
            flex: 1 1; }
  .alert--warning {
    background-color: #fb8c00 !important; }
  .alert--error {
    background-color: #dc3545 !important; }
  .alert--info {
    background-color: aqua; }
  .alert--success {
    background: linear-gradient(to left, #71c476, #4cab51); }
  .alert__icon {
    font-size: 1.7rem;
    margin-right: 2rem; }
  .alert__button {
    padding: 1rem 0;
    cursor: pointer; }

.breadcrumb {
  background-color: #f7f7f7;
  font-size: 0.9rem; }
  .breadcrumb .breadcrumb-item a {
    color: aqua; }

.nav-bar {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 5rem;
  background-color: #fff;
  top: 0;
  position: fixed;
  left: 19rem;
  width: calc(100% - 19rem);
  box-shadow: 0.1rem 0 0.1rem 0 rgba(0, 0, 0, 0.5);
  z-index: 4;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-align-content: center;
          align-content: center;
  border-left: 1px solid #dbdbdb; }
  .nav-bar__title {
    margin-top: 5px;
    margin-left: 2rem;
    padding-left: 11px; }
  .nav-bar__menu {
    margin-top: 10px; }
  .nav-bar__item {
    margin-right: 2rem;
    position: relative; }
    .nav-bar__item .fa {
      color: #b3b3b2;
      font-size: 1.4rem; }
  .nav-bar__news {
    top: -0.15rem;
    right: 0;
    position: absolute;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    background-color: #dc3545; }

.user-details {
  cursor: pointer;
  padding: 5px;
  display: "flex";
  -webkit-flex-direction: "row";
          flex-direction: "row";
  -webkit-justify-content: "space-between";
          justify-content: "space-between";
  -webkit-align-items: "center";
          align-items: "center"; }
  .user-details__photo, .user-details__photo-container {
    border: 2px solid #dee2e6; }
  .user-details__photo-container {
    color: #b3b3b2;
    font-size: 1.4rem; }
  .user-details__profile {
    font-size: 1.6rem; }
  .user-details__name {
    font-size: 0.9rem;
    font-size: 14px;
    margin: 0 1rem;
    font-weight: 700; }
  .user-details__top {
    padding-top: 5px !important; }
  .user-details__bottom {
    padding-bottom: 5px !important;
    color: "gray"; }
  .user-details__icon {
    font-size: 10px;
    font-weight: 400; }

.dropdown-item {
  padding: 0px;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 14px;
  font-weight: 500; }

.dropdown-item a {
  font-family: "Lato"; }

.side-bar__list {
  padding: 0 0 2rem 0;
  height: 100%;
  position: fixed;
  width: 19rem;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.06);
  background-color: #eff1f5;
  z-index: 5; }

.side-bar__item {
  font-weight: 500;
  margin: 0 10px; }
  .side-bar__item:first-child {
    margin-top: 100px; }
  .side-bar__item:hover {
    background-color: #fdc96e6e;
    border-radius: 5px;
    color: black; }
  .side-bar__item--active {
    margin: 0 10px;
    border-radius: 5px;
    background: linear-gradient(60deg, #ffa726, #fb8c00) !important;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4);
    font-weight: 700 !important; }
    .side-bar__item--active .side-bar__link {
      color: #FFF !important; }
    .side-bar__item--active .side-bar__icon {
      fill: #FFF !important; }

.side-bar__block, .side-bar__link {
  height: 100%;
  width: 100%;
  padding: 1rem;
  cursor: pointer; }

.side-bar__icon {
  margin-right: 1rem;
  fill: #000; }

.side-bar-collapsible__item {
  font-weight: 500;
  margin: 0 10px; }
  .side-bar-collapsible__item:hover {
    background-color: #fdc96e6e;
    border-radius: 5px; }
  .side-bar-collapsible__item--active {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4);
    border-radius: 5px;
    background: linear-gradient(60deg, #ffa726, #fb8c00) !important;
    font-weight: 700 !important;
    color: #FFF; }
    .side-bar-collapsible__item--active .side-bar-collapsible__icon {
      fill: #FFF; }
    .side-bar-collapsible__item--active:hover {
      background-color: #fdc86e; }

.side-bar-collapsible__sub-item {
  background-color: #eff1f5 !important;
  font-weight: 500;
  color: black !important; }
  .side-bar-collapsible__sub-item:hover {
    color: #FFF !important;
    background-color: #fdc86e !important; }
  .side-bar-collapsible__sub-item--active {
    border-left: .7em solid #ffa50d !important;
    border-radius: unset !important; }
    .side-bar-collapsible__sub-item--active .side-bar-collapsible__link {
      color: black !important; }
    .side-bar-collapsible__sub-item--active .side-bar-collapsible__icon {
      fill: black !important; }

.side-bar-collapsible__block, .side-bar-collapsible__link {
  height: 100%;
  width: 100%;
  padding: 1rem;
  cursor: pointer; }

.side-bar-collapsible__icon {
  margin-right: 1rem;
  fill: #000; }

.side-bar-collapsible__caret {
  width: 8px;
  height: 8px;
  fill: #000; }

.search-bar {
  margin: 2rem 0 2rem 0;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-right: unset; }
  .search-bar__date-input {
    margin-left: 50px;
    padding: 0px; }
  .search-bar__custom-padding {
    padding: 0px; }
  .search-bar .form-control {
    height: 40px;
    padding: 16px;
    float: none;
    background-color: #fff;
    margin-top: 10px; }
  .search-bar__absolute {
    position: absolute;
    right: 7%;
    margin-top: -42px !important;
    -webkit-flex-direction: column;
            flex-direction: column;
    display: -webkit-flex;
    display: flex;
    width: auto;
    -webkit-align-items: flex-end;
    align-items: flex-end;
    -webkit-align-self: flex-end;
    align-self: flex-end;
    z-index: 100; }
  .search-bar__btn {
    border: none;
    text-align: inherit;
    border: 1px solid white; }
    .search-bar__btn, .search-bar__btn:hover, .search-bar__btn:active {
      border: none;
      background-color: transparent; }
  .search-bar .search-bar__btn.btn {
    background-color: white;
    border: 1px solid #ced4da;
    border-left: unset;
    margin-top: 10px;
    min-width: 30px !important;
    -webkit-justify-content: center;
            justify-content: center; }
    .search-bar .search-bar__btn.btn:hover, .search-bar .search-bar__btn.btn:active {
      background-color: transparent; }
  .search-bar__input-group {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    margin-right: 2rem; }
    .search-bar__input-group .form-control {
      height: 40px;
      padding: 16px;
      width: 25vw; }
  .search-bar__little-input-group {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    margin-left: 5px;
    margin-right: 0px; }
    .search-bar__little-input-group .form-control {
      height: 40px;
      padding: 16px;
      min-width: 18vw; }
  .search-bar__icon {
    font-weight: bold;
    color: black;
    cursor: pointer; }
  .search-bar__flex_wrap {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }

.btn {
  min-width: 120px; }

.btn-outline-secondary {
  border-color: #e9ecef; }
  .btn-outline-secondary:hover {
    background-color: #d7dadd;
    border-color: #d7dadd; }

.btn-outline-secondary.disabled {
  border-color: #c4c4c4; }
  .btn-outline-secondary.disabled:hover {
    border-color: #c4c4c4;
    background-color: unset; }

.btn.small {
  min-width: 30px;
  min-height: 30px; }

select.form-control {
  padding: 0px 5px !important; }

.custom-margin {
  margin-left: 30px; }

.fallback-spinner {
  height: 100vh;
  width: 100wh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.nav-tabs {
  background-color: #f7f7f7;
  padding: 0; }
  .nav-tabs .tab {
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    .nav-tabs .tab--active {
      background-color: #fff; }
    .nav-tabs .tab__header {
      -webkit-flex: 1 1;
              flex: 1 1;
      height: 100%;
      width: 100%;
      cursor: pointer;
      text-align: center;
      font-weight: 600; }
      .nav-tabs .tab__header, .nav-tabs .tab__header:hover {
        border-color: #fff; }
      .nav-tabs .tab__header .nav-link {
        font-size: 14px;
        font-weight: 500; }

.react-autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

.icon-input {
  border: 1px solid #cbcbd2;
  border-radius: 0.25rem; }
  .icon-input:not(:last-child) {
    margin-bottom: 1rem; }
  .icon-input:focus-within {
    border-color: #feac22; }
    .icon-input:focus-within i {
      color: #feac22; }
  .icon-input .input-group-append {
    padding: 1rem;
    border-right: 1px solid #cbcbd2; }
  .icon-input .form-control, .icon-input .form-control:focus {
    border-color: #fff;
    box-shadow: none;
    outline: 0 none; }

.input__error {
  color: #dc3545;
  margin-bottom: 1rem;
  width: 100%; }

.upload-btn {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  position: absolute;
  margin-top: 20px !important;
  background: whitesmoke;
  color: #28a745;
  width: 4rem;
  height: 4rem;
  border: none;
  box-shadow: 0 2px 0 #b3b3b2; }
  .upload-btn:hover:not(.btn-enabled) {
    background: #b3b3b2;
    box-shadow: 0 1px 0 #b3b3b2; }
  .upload-btn:active {
    background: #fcfcfc;
    box-shadow: 0 1px 0 #b3b3b2; }
  .upload-btn.btn-enabled {
    cursor: not-allowed; }
  .upload-btn:disabled {
    opacity: unset; }

.contract {
  cursor: not-allowed; }

.limit {
  float: right;
  max-width: 100px !important; }

.input-container {
  width: 100%; }
  .input-container .label {
    margin-left: 38px; }

.invalid-file {
  margin-top: 1.8rem;
  font-size: 80%;
  color: #dc3545; }

.card {
  border-radius: 10px;
  box-shadow: 0px 4px 13px 0px #ececec;
  border: none; }
  .card-no-boxshadow {
    box-shadow: unset; }
  .card__title {
    border-bottom: 1px solid #dee2e6; }
  .card-body__center {
    min-width: 30vw;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .card-header__warning {
    background: linear-gradient(to bottom, #feac22, #e68d00);
    color: #fff; }
  .card-header__primary {
    background: linear-gradient(to left, #71c476, #4cab51);
    color: #fff; }
  .card-footer {
    width: 100%;
    background-color: #fff; }

.dark-boxshadow {
  box-shadow: 0px 4px 13px 0px #adadad; }

.graphql-result {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1; }
  .graphql-result__container {
    -webkit-flex: 1 1;
            flex: 1 1;
    height: 25vh;
    padding: 2rem; }

.modal__header {
  background: linear-gradient(to left, #71c476, #4cab51);
  color: #fff; }

.modal__body {
  min-height: 5rem;
  padding: 1rem;
  text-align: center;
  font-size: 0.9rem; }

.modal__footer {
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.modal__title {
  font-size: 14px;
  font-weight: 600; }

.img-profile {
  border-radius: 50%; }

.list-group {
  padding: 0;
  margin: 6px; }
  .list-group-item,
  .list-group-item :last-child {
    border-bottom-right-radius: none;
    border-bottom-left-radius: none;
    border: none; }
  .list-group__item {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-bottom: 1rem;
    font-weight: 1.4rem; }
  .list-group__label {
    -webkit-flex: 1 1;
            flex: 1 1;
    letter-spacing: 0.02em;
    margin-bottom: 0.3rem;
    font-weight: bold;
    text-transform: uppercase; }
  .list-group__value {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin-left: 20px; }
  .list-group__full-value {
    -webkit-flex: 6 1;
            flex: 6 1; }

.search .fa-search {
  margin-left: -1.8rem; }

.list {
  min-height: 15rem;
  max-height: 25rem; }
  .list--disabled {
    opacity: 0.6; }

.list-item {
  border: 1px solid #dee2e6;
  border-left: 0;
  border-right: 0;
  cursor: pointer;
  padding: 0; }
  .list-item__container {
    padding: 1rem;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    .list-item__container:hover {
      background-color: #eeeeee; }
    .list-item__container--selected {
      background: linear-gradient(to left, #71c476, #4cab51);
      color: #fff; }
  .list-item__name {
    margin-left: 1rem; }

.img-rounded {
  max-width: 50px;
  height: 50px;
  border-radius: 50%; }

.steps {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 10px 0; }
  .steps .num {
    border: 2px solid #68c06c;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer; }
    .steps .num:hover {
      background: #adb5bd75;
      border-color: #adb5bd75; }
  .steps .active {
    background: #6aba6d;
    color: white; }
  .steps .line {
    width: 12%;
    height: 3px;
    background: #69bf6d; }

.title-item {
  border: 1px solid #e2e2e2;
  background: no-repeat;
  color: #28292b;
  margin-left: 6px;
  margin-right: 6px; }

.menu-category {
  cursor: pointer;
  background-color: #f2f3f2; }

.sub-category {
  cursor: pointer;
  background-color: #fcfcfc;
  color: #848484;
  margin: 5px; }
  .sub-category__label {
    font-size: 1.1rem;
    font-weight: normal; }

.menus,
.sub-categories {
  padding: 1rem;
  border-radius: 0.25rem;
  border: 1px solid #dee2e6;
  border-top: none; }

.menus.list-group {
  margin: 0px 5px 5px 5px; }

.sub-category-active {
  border-bottom: 0px !important;
  margin-bottom: 0px; }

.menu-category,
.sub-category,
.menu-item {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border: 1px solid #dee2e6; }

.draggable-container:not(:last-child) {
  margin-bottom: 1rem !important; }

.menus {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  max-height: 20rem;
  padding-left: 0;
  padding-right: 0; }

.menu-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  cursor: pointer; }
  .menu-item .invalid-feedback {
    -webkit-flex: 1 1;
            flex: 1 1;
    font-size: 0.8rem; }
  .menu-item--selected {
    color: #4cab51; }
  .menu-item__label {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    font-size: 1.2rem;
    font-weight: 500;
    -webkit-flex: 1 1;
            flex: 1 1; }
  .menu-item__icon {
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-justify-content: "flex-end";
            justify-content: "flex-end"; }

.card-menu__item {
  border-radius: 10px;
  max-width: 12rem;
  min-width: 12rem;
  padding: 1.2rem;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  border-color: rgba(223, 225, 229, 0);
  position: relative;
  overflow: hidden !important;
  margin: 0.7rem;
  display: table; }

.card-menu__price {
  color: #71c476;
  font-size: 1.2rem;
  text-align: center; }

.card-menu__image {
  border-radius: 10px;
  width: 7rem;
  height: 7rem;
  max-width: 11rem;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto; }

.card-menu__sub-container {
  position: relative; }

.card-menu__content {
  text-align: center; }

.card-menu__content p {
  margin-bottom: 0;
  color: #939393;
  font-size: 0.8rem; }

.card-menu__title {
  font-size: 1rem; }

.card-menu__out-of-stock {
  height: 10px;
  font-size: 10px;
  text-align: center;
  position: absolute;
  color: white;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
  display: table;
  top: -30px;
  left: -70px;
  padding: 0 48px;
  border: 5px;
  font-weight: bold;
  z-index: 2; }

.card-menu__disabled {
  height: 10px;
  position: absolute;
  font-size: 10px;
  text-align: center;
  color: white;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
  display: table;
  top: -30px;
  left: -70px;
  padding: 0 43px;
  border: 5px;
  font-weight: bold;
  z-index: 2; }

.card-menu-detail__item {
  border-radius: 10px;
  max-width: 16rem;
  min-width: 16rem;
  padding: 0rem;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  border-color: rgba(223, 225, 229, 0);
  position: relative;
  overflow: hidden !important;
  margin: 0.7rem;
  margin-left: 0rem;
  margin-bottom: 1.2rem;
  display: table; }

.card-menu-detail__price {
  color: #ef9304;
  font-size: 1.2rem;
  text-align: end;
  padding: 0.5rem;
  margin-right: 0.2rem;
  font-weight: bolder; }

.card-menu-detail__image {
  border-radius: 10px;
  width: 12rem;
  height: 12rem;
  max-width: 12rem;
  min-width: 12rem;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto; }

.card-menu-detail__sub-container {
  position: relative;
  padding: 0rem 3rem 1rem 3rem; }

.card-menu-detail__empty-sub-container {
  position: relative;
  padding: 1rem 2rem 1rem 2rem; }

.card-menu-detail__sub-sub-container {
  padding-left: 2.5rem; }

.card-menu-detail__content {
  text-align: center; }

.card-menu-detail__content p {
  margin-bottom: 0;
  color: #939393;
  font-size: 0.8rem; }

.card-menu-detail__title {
  font-size: 1rem; }

.card-menu-detail__subtitle {
  font-size: 0.8rem;
  font-weight: bold;
  text-align: end;
  padding-right: 2rem; }

.photo {
  position: relative; }

.camera {
  position: absolute;
  right: 6px;
  bottom: 2px;
  background-color: #dee2e6;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #fff;
  text-align: center;
  font-size: 0.8rem;
  padding-top: 0.55rem; }

.product-item {
  overflow: hidden;
  padding: 10px; }
  .product-item > div:not(:last-child) {
    margin-bottom: 10px; }
  .product-item__position .card-1 {
    background: #f2f2f2;
    color: #000;
    border: 1px solid #d4d4d4;
    padding: 6px;
    min-width: 150px;
    position: relative;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 15px 0px 0px 15px;
    cursor: pointer; }
    .product-item__position .card-1__day span:first-child {
      font-weight: bold;
      font-size: 1.1rem; }
    .product-item__position .card-1 i {
      position: absolute;
      right: 6px;
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      font-weight: 100;
      font-size: 1.5rem;
      transition: all 1s ease 0s; }
  .product-item__position .card-2 {
    -webkit-flex: 1 1;
            flex: 1 1;
    border: 1px solid #d4d4d4;
    -webkit-align-items: center;
            align-items: center;
    height: 100%; }
    .product-item__position .card-2 .sessions-container {
      display: -webkit-flex;
      display: flex;
      width: 100%;
      padding: 5px 0;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; }
    .product-item__position .card-2__Seance {
      padding: 0 35px;
      min-width: 300px; }
  .product-item__position #collapse-id {
    transition: all 1.5s ease;
    height: 100%;
    width: 100%; }
  .product-item__position .collapseComponent {
    overflow: hidden; }
  .product-item__position #collapse-id.in {
    -webkit-flex: none;
            flex: none;
    overflow: hidden;
    left: -100%; }
    .product-item__position #collapse-id.in .test {
      left: -100% !important;
      transition: all 10s ease !important;
      position: "absolute";
      display: "flex"; }

.collapse-component {
  overflow: hidden;
  z-index: 2;
  position: relative;
  left: 0; }
  .collapse-component__cart {
    position: relative;
    left: 0;
    transition: all 1.5s ease;
    z-index: -1; }

.preview {
  width: 100%; }
  .preview > div:last-child {
    margin-bottom: 10px; }
  .preview .card-1 {
    border-radius: unset;
    margin: 0 7px;
    min-width: auto;
    color: black;
    background: none;
    border: 1px solid #dfdfdf;
    -webkit-align-items: start;
            align-items: start;
    font-size: 1.1rem;
    cursor: auto; }
  .preview .card-1__day > span:first-child {
    color: #f1a42d; }

.input-range {
  height: 1.2rem !important; }
  .input-range__slider {
    height: 1.2rem !important;
    width: 1.2rem !important;
    background: #fff !important;
    border: 2.5px solid #3f51b5 !important; }
  .input-range--disabled .input-range__slider {
    background: #eeeeee !important;
    border-width: 0px !important; }

@-webkit-keyframes movemydiv {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  30% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  70% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movemydiv {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  30% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  70% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@-webkit-keyframes you-spin-me-round-round-baby-right-round {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

.title__list_driver {
  padding: 0;
  margin: 20px 0;
  font-size: 16px; }

.collapse__button {
  font-weight: bold;
  padding: 5px;
  font-size: 13px;
  border-radius: 5px;
  margin-bottom: 7px; }

.collapse__button:focus {
  outline: none; }

.collapse__icon {
  font-size: 20px; }

.price-quantuty {
  font-weight: 500;
  color: orange;
  margin-left: 10px; }

.card__title {
  background-image: linear-gradient(to right, #f6ad00, #f67000);
  max-width: 350px;
  padding: 14px;
  color: white;
  margin-top: -24px;
  margin-left: 15px;
  border-radius: 3px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center; }
  .card__title__large {
    max-width: 450px; }

.comment {
  padding: 5px 7px;
  color: #9b9797;
  border: none;
  border-radius: 2px; }

.old-driver {
  color: #7f7f7f; }

.mt-6 {
  margin-top: 4rem !important; }

.timer {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #f3f3f3;
  color: red;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 10px;
  width: 83px;
  height: 44; }
  .timer:hover .timer__img {
    -webkit-animation: movemydiv 60s ease-in-out infinite alternate;
    animation: movemydiv 3s ease-in-out infinite alternate; }
  .timer__img {
    -webkit-animation: movemydiv 60s ease-in-out alternate;
    animation: movemydiv 3s ease-in-out alternate; }
    .timer__img:hover {
      -webkit-animation: movemydiv 60s ease-in-out infinite alternate;
      animation: movemydiv 3s ease-in-out infinite alternate; }
  .timer__text {
    margin-left: 10px;
    font-size: 16px; }

.emptyDriverList {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: #f3f3f3;
  padding: 4rem; }
  .emptyDriverList__text {
    color: #7f7f7f; }
  .emptyDriverList__button {
    font-size: 18px;
    margin-top: 20px; }

.not__allowed {
  cursor: not-allowed !important; }

.header {
  background: #e6e6e6;
  padding: 10px; }
  .header__title {
    font-size: 20px;
    color: #555555; }
  .header .reload:hover {
    -webkit-animation: movemydiv 3s cubic-bezier(0.68, -0.55, 0.27, 1.55) alternate;
    animation: movemydiv 3s cubic-bezier(0.68, -0.55, 0.27, 1.55) alternate; }

.driver-items {
  max-height: 302px;
  overflow: auto; }
  .driver-items .driver-item {
    border-bottom: 1px solid #00000020;
    display: block; }
    .driver-items .driver-item .driver {
      max-width: 770px;
      padding: 5px;
      padding-left: 10px; }
      .driver-items .driver-item .driver__img-view {
        border-radius: 50%;
        height: 50px;
        width: 47px;
        border: 1px solid #dfdfdf;
        overflow: hidden;
        display: block; }
      .driver-items .driver-item .driver__name {
        font-size: 17px;
        color: #555555; }
      .driver-items .driver-item .driver__phone {
        font-size: 16px;
        color: #555555; }

.sendList__button {
  width: 100%;
  position: absolute;
  bottom: -50px;
  z-index: 0;
  border-radius: 0;
  border: 1px solid #28a7;
  padding: 10px;
  font-size: 19px; }

.timerWaiteingDriver {
  position: relative;
  color: black; }

.interruptions tbody tr {
  min-width: 240px;
  border: 1px solid #dee2e6;
  max-width: 48%;
  margin: 0 1%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center; }

.interruptions tbody td {
  border: none; }

.interruptions tbody i {
  cursor: pointer !important;
  padding: 0.75rem 29px; }

@media screen and (max-width: 863px) {
  .interruptions .table-striped tbody tr:nth-of-type(2n) {
    background-color: #0000000d !important; }
  .interruptions .table-striped tbody tr:nth-of-type(2n + 1) {
    background-color: white !important; } }

.tooltipStore {
  position: absolute;
  top: 50%;
  right: 15%;
  z-index: 20; }

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: black;
  text-align: center;
  background-color: white !important;
  border: 1px #ef9502 solid;
  border-radius: 0.25rem; }

iframe {
  width: 100%;
  height: 100%; }

.check {
  cursor: pointer;
  position: relative;
  margin: auto;
  width: 18px;
  height: 18px;
  -webkit-tap-highlight-color: transparent;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }
  .check:before {
    content: "";
    position: absolute;
    top: -15px;
    left: -15px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: rgba(34, 50, 84, 0.03);
    opacity: 0;
    transition: opacity .2s ease; }
  .check svg {
    position: relative;
    z-index: 1;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #C8CCD4;
    stroke-width: 1.5;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    transition: all .2s ease; }
  .check path {
    stroke-dasharray: 60;
    stroke-dashoffset: 0; }
  .check polyline {
    stroke-dasharray: 22;
    stroke-dashoffset: 66; }
  .check:hover:before {
    opacity: 1; }
  .check:hover svg {
    stroke: #4285F4; }

.cbxchecked .check svg {
  stroke: #4285F4; }
  .cbxchecked .check svg path {
    stroke-dashoffset: 60;
    transition: all .3s linear; }
  .cbxchecked .check svg polyline {
    stroke-dashoffset: 42;
    transition: all .2s linear;
    transition-delay: .15s; }

.notifications {
  position: relative; }
  .notifications__container {
    position: absolute;
    top: 150%;
    right: 30%;
    background-color: #fff;
    width: 30rem; }
  .notifications__titleContainer {
    border-bottom: 1px solid #dee2e6; }
  .notifications__title {
    color: #4cab51;
    font-weight: 550;
    padding: 0.5rem;
    text-align: left; }
  .notifications__right {
    color: #5049d6;
    padding: 0.5rem;
    text-align: right;
    text-decoration: underline;
    font-size: 10px; }
  .notifications__list {
    max-height: 65vh;
    overflow-y: scroll !important; }
    .notifications__list::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5; }
    .notifications__list::-webkit-scrollbar {
      width: 12px; }
  .notifications__footer {
    border-top: 1px solid #dee2e6;
    padding: 0.5rem; }
  .notifications__marker, .notifications__container {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25); }
  .notifications__marker {
    position: absolute;
    background-color: #f7f7f7;
    height: 1rem;
    width: 2rem;
    --webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
            clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    top: 100%;
    right: 1.7rem; }
  .notifications__item {
    padding: 0.3rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100%;
    border-bottom: 1px solid #dee2e6; }
    .notifications__item--not-looked {
      background-color: whitesmoke; }
    .notifications__item:hover {
      background-color: #f1aa26; }
  .notifications__message {
    font-size: 0.9rem;
    padding: 0.4rem; }
  .notifications__date {
    -webkit-align-self: flex-end;
            align-self: flex-end;
    font-size: 0.6rem;
    color: #b3b3b2;
    text-align: right; }

.nav-bar {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 5rem;
  background-color: #fff;
  top: 0;
  position: fixed;
  left: 19rem;
  width: calc(100% - 19rem);
  box-shadow: 0.1rem 0 0.1rem 0 rgba(0, 0, 0, 0.5);
  z-index: 4; }
  .nav-bar__title {
    margin-left: 2rem; }
  .nav-bar__item {
    margin-right: 2rem;
    position: relative; }
    .nav-bar__item .fa {
      color: #b3b3b2;
      font-size: 1.4rem; }
  .nav-bar__news {
    top: -1rem;
    left: 0.5rem;
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    text-align: center;
    border-radius: 50%;
    background-color: #dc3545;
    color: #fff; }

.profile-image-container {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%; }
  .profile-image-container img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    vertical-align: top; }
  .profile-image-container:after {
    content: '\A';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: all 0.5s;
    -webkit-transition: all 0.5s; }
  .profile-image-container:hover:after {
    opacity: 1; }
  .profile-image-container .crop-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -15px;
    margin-left: -15px;
    width: 30px;
    height: 30px;
    z-index: 10; }
    .profile-image-container .crop-btn__icon {
      width: 100%;
      height: 100%;
      fill: #FFF !important; }

.profile-image-container-no-hover {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%; }
  .profile-image-container-no-hover img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    vertical-align: top; }

.modal-transparent-fit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto; }
  .modal-transparent-fit .modal-content {
    background: none; }

.crop-btns-container {
  position: absolute;
  bottom: -55px;
  left: 50%;
  margin-left: -70px;
  height: 50px;
  width: 140px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center; }
  .crop-btns-container :hover {
    transition: all 0.1s;
    -webkit-transition: all 0.1s;
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }

.checkbox-btn {
  border-radius: 50%;
  background: linear-gradient(60deg, #66bb6a, #43a047);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.57), 0 7px 10px -5px rgba(76, 175, 80, 0.4);
  border: 1px solid #43a047;
  text-align: center;
  width: 60px;
  height: 60px;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  .checkbox-btn-red {
    background: linear-gradient(60deg, #ef5350, #e53935);
    border: 1px solid #e53935;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.57), 0 7px 10px -5px rgba(244, 67, 54, 0.4); }
  .checkbox-btn i {
    font-size: 30px;
    line-height: 0.5;
    vertical-align: text-bottom;
    color: #FFF !important; }

