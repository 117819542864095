@keyframes side-bar-animation {
  0% {
    border-left: 0.01rem solid #fff;
  }

  25% {
    border-left: 0.05rem solid #fff;
  }

  50% {
    border-left: 0.1rem solid #fff;
  }

  75% {
    border-left: 0.2rem solid #fff;
  }

  100% {
    border-left: 0.3rem solid #fff;
  }
}

@-webkit-keyframes side-bar-animation {
  0% {
    border-left: 0.01rem solid #fff;
  }

  25% {
    border-left: 0.05rem solid #fff;
  }

  50% {
    border-left: 0.1rem solid #fff;
  }

  75% {
    border-left: 0.2rem solid #fff;
  }

  100% {
    border-left: 0.3rem solid #fff;
  }
}

@-webkit-keyframes alertAnimation {
  0% {
    transform: translateY(-2rem);
  }

  30% {
    transform: translateY(-2rem);
  }

  60% {
    transform: translateY(-1.5rem);
  }

  90% {
    transform: translateY(1rem);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes alertAnimation {
  0% {
    transform: translateY(-2rem);
  }

  30% {
    transform: translateY(-2rem);
  }

  60% {
    transform: translateY(-1.5rem);
  }

  90% {
    transform: translateY(1rem);
  }

  100% {
    transform: translateY(0);
  }
}
.rotate90{
  transform: rotate(90deg);
  transition: all 0.4s ease 0s;

}