.side-bar {
  &__list {
    padding: 0 0 $small-margin 0;
    height: 100%;
    position: fixed;
    @extend %scrollable;
    width: $side-bar-width;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.06);
    background-color: #eff1f5;
    z-index: 5;
  }

  &__item {
    font-weight: 500;
    margin: 0 10px;
    &:first-child {
      margin-top: 100px;
    }
    &:hover {
      background-color: #fdc96e6e;
      border-radius: 5px;
      color: black;
    }
    &--active {
      margin: 0 10px;
      border-radius: 5px;
      background: $gradient-orange !important;
      -webkit-box-shadow:0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px rgba(255,152,0,.4);
      -moz-box-shadow: 0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px rgba(255,152,0,.4);
      box-shadow: 0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px rgba(255,152,0,.4);
      font-weight: 700 !important;
   

      .side-bar__link {
        color: #FFF !important;
      }
      .side-bar__icon {
        fill: #FFF !important;
      }
    }
  }
  &__block,
  &__link {
    height: 100%;
    width: 100%;
    @extend %flex-align-center;
    padding: $x-small-margin;
    cursor: pointer;
  }

  &__icon {
    @extend %x-small-photo;
    margin-right: $x-small-margin;
    fill: $color-text;
  }
}

.side-bar-collapsible {
  &__item {
    font-weight: 500;
    margin: 0 10px;
    &:hover {
      background-color: #fdc96e6e;
      border-radius: 5px;
    }
    &--active {
      -webkit-box-shadow:0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px rgba(255,152,0,.4);
      -moz-box-shadow: 0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px rgba(255,152,0,.4);
      box-shadow: 0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px rgba(255,152,0,.4);
      border-radius: 5px;
      background: $gradient-orange !important;
      font-weight: 700 !important;
      color: #FFF;
      .side-bar-collapsible__icon {
        fill: #FFF;
      }
    &:hover {
      background-color: #fdc86e;
    }
  }
}
  &__sub-item {
    background-color: #eff1f5 !important;
    font-weight: 500;
    color: black !important;

    &:hover {
      color: #FFF !important;
      background-color: #fdc86e !important;
    }
    &--active {
      // background: #feac22 !important;
      border-left: .7em solid #ffa50d !important;
      border-radius: unset !important;

      .side-bar-collapsible__link {
        color: black !important;
      }
      .side-bar-collapsible__icon {
        fill: black !important;
      }
    }
  }

  &__block,
  &__link {
    height: 100%;
    width: 100%;
    @extend %flex-align-center;
    padding: $x-small-margin;
    cursor: pointer;
  }
  &__icon {
    @extend %x-small-photo;
    margin-right: $x-small-margin;
    fill: $color-text;
  }
  &__caret {
    width: 8px;
    height: 8px;
    fill: $color-text;
  }
}
