$a: 100%;
.nav-bar {
  @extend %flex-align-center;
  justify-content: space-between;
  height: $nav-bar-height;
  background-color: #fff;
  top: 0;
  position: fixed;
  left: $side-bar-width;
  width: calc(#{$a} - #{$side-bar-width});
  box-shadow: $shadow-light-1;
  z-index: 4;
  flex: 1;
  align-content: center;
  border-left: 1px solid #dbdbdb;
  &__title {
    margin-top: 5px;
    margin-left: $small-margin;
    padding-left: 11px;
  }

  &__menu {
    margin-top: 10px;
    @extend %small-padding-horizontal;
    @extend %flex-align-center;
  }

  &__item {
    margin-right: $small-margin;
    position: relative;
    .fa {
      color: $color-grey-light-2;
      font-size: $font-size-small;
    }
  }

  &__news {
    top: -0.15rem;
    right: 0;
    position: absolute;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    background-color: $color-error;
  }
}

.user-details {
  @extend %flex-align-center;
  cursor: pointer;
  padding: 5px;
  display: "flex";
  flex-direction: "row";
  justify-content: "space-between";
  align-items: "center";
  &__photo,
  &__photo-container {
    @extend %medium-photo;
    border: $border-img;
  }

  &__photo-container {
    @extend %flex-center;
    color: $color-grey-light-2;
    font-size: $font-size-small;
  }

  &__profile {
    font-size: $font-size-medium;
  }

  &__name {
    font-size: $font-size-x-small;
    font-size: 14px;
    margin: 0 1rem;
    font-weight: 700;
  }
  &__top {
    padding-top: 5px !important;
  }
  &__bottom {
    padding-bottom: 5px !important;
    color: "gray";
  }
  &__icon {
    font-size: 10px;
    font-weight: 400;
  }
}

.dropdown-item {
  padding: 0px;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 14px;
  font-weight: 500;
}
.dropdown-item a {
  font-family: "Lato";
}
