.product-item {
  overflow: hidden;
  padding: 10px;
  & > div:not(:last-child) {
    margin-bottom: 10px;
  }
  &__position {
    .card-1 {
      background: #f2f2f2;
      color: #000;
      border: 1px solid #d4d4d4;
      padding: 6px;
      min-width: 150px;
      position: relative;
      justify-content: center;
      align-items: center;
      border-radius: 15px 0px 0px 15px;
      cursor: pointer;
      &__day {
        span:first-child {
          font-weight: bold;
          font-size: 1.1rem;
        }
      }
      i {
        position: absolute;
        right: 6px;
        transform: rotate(90deg);
        font-weight: 100;
        font-size: 1.5rem;
        transition: all 1s ease 0s;
      }
    }

    .card-2 {
      flex: 1;
      border: 1px solid #d4d4d4;
      align-items: center;
      height: 100%;

      .sessions-container {
        display: flex;
        width: 100%;
        padding: 5px 0;
        flex-wrap: wrap;
      }

      &__Seance {
        padding: 0 35px;
        min-width: 300px;
      }
    }

    #collapse-id {
      transition: all 1.5s ease;
      height: 100%;
      width: 100%;
    }
    .collapseComponent {
      overflow: hidden;
    }
    #collapse-id.in {
      flex: none;
      overflow: hidden;
      left: -100%;
      .test {
        left: -100% !important;
        transition: all 10s ease !important;
        position: "absolute";
        display: "flex";
      }
    }
  }
}
.collapse-component {
  overflow: hidden;
  z-index: 2;
  position: relative;
  left: 0;

  &__cart {
    position: relative;
    left: 0;
    transition: all 1.5s ease;
    z-index: -1;
  }
}

.preview {
  width: 100%;

  & > div:last-child {
    margin-bottom: 10px;
  }
  .card-1 {
    // padding: 6px 13px;
    border-radius: unset;
    margin: 0 7px;
    min-width: auto;
    color: black;
    background: none;
    border: 1px solid #dfdfdf;
    align-items: start;
    font-size: 1.1rem;
    cursor: auto;
  }
  .card-1__day {
    & > span:first-child {
      color: #f1a42d;
    }
    // color: #f1a42d;
  }
}
