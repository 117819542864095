@keyframes movemydiv {
  from {
    // transform: translateX(0px);
    transform: rotate(0deg);
  }

  30% {
    transform: rotate(180deg);
  }
  70% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes you-spin-me-round-round-baby-right-round {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
.title__list_driver {
  padding: 0;
  margin: 20px 0;
  font-size: 16px;
}
.collapse {
  &__button {
    font-weight: bold;
    padding: 5px;
    font-size: 13px;
    border-radius: 5px;
    margin-bottom: 7px;
  }
  &__button:focus {
    outline: none;
  }
  &__icon {
    font-size: 20px;
  }
}
.price-quantuty {
  font-weight: 500;
  color: orange;
  margin-left: 10px;
}
.card__title {
  background-image: linear-gradient(to right, #f6ad00, #f67000);
  max-width: 350px;
  padding: 14px;
  color: white;
  margin-top: -24px;
  margin-left: 15px;
  border-radius: 3px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  &__large {
    max-width: 450px;
  }
}
.comment {
  padding: 5px 7px;
  color: #9b9797;
  border: none;
  border-radius: 2px;
}

.old-driver {
  color: #7f7f7f;
}

.mt-6 {
  margin-top: 4rem !important;
}

.timer {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #f3f3f3;
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 83px;
  height: 44;
  &:hover &__img {
    -webkit-animation: movemydiv 60s ease-in-out infinite alternate;
    animation: movemydiv 3s ease-in-out infinite alternate;
  }

  &__img {
    -webkit-animation: movemydiv 60s ease-in-out alternate;
    animation: movemydiv 3s ease-in-out alternate;
    &:hover {
      -webkit-animation: movemydiv 60s ease-in-out infinite alternate;
      animation: movemydiv 3s ease-in-out infinite alternate;
    }
  }
  &__text {
    margin-left: 10px;
    font-size: 16px;
  }
}
.emptyDriverList {
  flex: 1 1 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(243, 243, 243);
  padding: 4rem;
  &__text {
    color: #7f7f7f;
  }
  &__button {
    font-size: 18px;
    margin-top: 20px;
  }
}
.not__allowed {
  cursor: not-allowed !important;
}
.header {
  background: #e6e6e6;
  padding: 10px;
  &__title {
    font-size: 20px;
    color: #555555;
  }
  .reload {
    &:hover {
      -webkit-animation: movemydiv 3s cubic-bezier(0.68, -0.55, 0.27, 1.55)
        alternate;
      animation: movemydiv 3s cubic-bezier(0.68, -0.55, 0.27, 1.55) alternate;
    }
  }
}
.driver-items {
  max-height: 302px;
  overflow: auto;
  .driver-item {
    border-bottom: 1px solid #00000020;
    display: block;

    .driver {
      max-width: 770px;
      padding: 5px;
      padding-left: 10px;

      &__img-view {
        border-radius: 50%;
        height: 50px;
        width: 47px;
        border: 1px solid #dfdfdf;
        overflow: hidden;
        display: block;
      }
      &__name {
        font-size: 17px;
        color: #555555;
      }
      &__phone {
        font-size: 16px;
        color: #555555;
      }
    }
  }
}
.sendList__button {
  width: 100%;
  position: absolute;
  bottom: -50px;
  z-index: 0;
  border-radius: 0;
  border: 1px solid #28a7;
  padding: 10px;
  font-size: 19px;
}
.timerWaiteingDriver {
  position: relative;
  color: black;
}
