.card {
  border-radius: 10px;
  -webkit-box-shadow: 0px 4px 13px 0px rgba(236, 236, 236, 1);
  -moz-box-shadow: 0px 4px 13px 0px rgba(236, 236, 236, 1);
  box-shadow: 0px 4px 13px 0px rgba(236, 236, 236, 1);
  border: none;
  &-no-boxshadow {
    box-shadow: unset;
  }
  
  &__title {
    border-bottom: $border-bottom-grey;
  }

  &-body__center {
    min-width: 30vw;
    @extend %flex-center;
    flex-direction: column;
  }

  &-header__warning {
    background: $logo-background-color;
    color: #fff;
  }

  &-header__primary {
    background: $color-primary;
    color: #fff;
  }

  &-footer {
    width: 100%;
    background-color: #fff;
  }
}
.dark-boxshadow{
  -webkit-box-shadow: 0px 4px 13px 0px #adadad;
-moz-box-shadow: 0px 4px 13px 0px #adadad;
box-shadow: 0px 4px 13px 0px #adadad; 
}