* {
  padding: 0;
  margin: 0;
}

*,
*::before,
*::before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 80%;
}

body {
  font-family: "Ubuntu";
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.01em;
  background-color: $background-color;
}
h5 {
  font-size: 16px;
  font-weight: 600;
}
.font-weight-bold {
  font-weight: 900 !important;
}
.fa-2x {
  font-size: 16px;
}
.badge {
  font-size: 100%;
}
.font-size-larger {
  font-size: 24px;
}
.font-weight-700 {
  font-weight: 700;
}

a,
a:hover {
  text-decoration: none;
  cursor: pointer;
  color: $color-text;
}

.private-container {
  display: flex;
  min-height: 100vh;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: $side-bar-width;
  margin-top: $nav-bar-height;
}

.container-fluid {
  padding: $small-margin;
  min-height: 100%;
  overflow-y: auto;
}

.public-container {
  @extend %flex-center;
  @extend %public-background;
  min-height: 100vh;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}

ul,
li {
  list-style: none;
}

button {
  &,
  &:visited {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: none;
  }
}

// select {
//   min-width: 5rem;
// }

#store-bo {
  min-height: 100vh;
  min-width: 100%;
  border: unset;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
