.breadcrumb {
	background-color: $background-color;
	font-size: $font-size-x-small;

	.breadcrumb-item {
		a {
			color: $color-info;
		}
	}
}
