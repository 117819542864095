.graphql-result {
	display: flex;
	flex-direction: column;
	flex: 1;

	&__container {
		flex: 1;
		height: 25vh;
		padding: $small-margin;
		@extend %flex-center;
	}
}
