.photo {
  position: relative;
}

.camera {
  position: absolute;
  right: 6px;
  bottom: 2px;
  background-color: #dee2e6;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #fff;
  text-align: center;
  font-size: 0.8rem;
  padding-top: 0.55rem;
}
