.alert {
	@extend %flex-align-center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	max-height: 5rem;
	width: 100%;
	padding: $small-margin;
	color: #fff;
	font-size: $font-size-x-small;
	font-weight: 400;
	margin-bottom: $x-small-margin;
	animation: alertAnimation 0.2s ease-in;
	--webkit-animation: alertAnimation 0.2s ease-in;
	border-radius: 0;

	&__message {
		flex: 1;
	}

	&--warning {
		background-color: $color-warning !important;
	}

	&--error {
		background-color: $color-error !important;
	}

	&--info {
		background-color: $color-info;
	}

	&--success {
		background: $color-primary;
	}

	&__icon {
		font-size: 1.7rem;
		margin-right: $small-margin;
	}

	&__button {
		padding: $x-small-margin 0;
		cursor: pointer;
	}
}
