.search {
  @extend %flex-center;

  .fa-search {
    margin-left: -1.8rem;
  }
}

.list {
  min-height: 15rem;
  max-height: 25rem;

  &--disabled {
    opacity: 0.6;
  }
}

.list-item {
  border: $border-bottom-grey;
  border-left: 0;
  border-right: 0;
  cursor: pointer;
  padding: 0;

  &__container {
    padding: $x-small-margin;
    align-items: center;
    flex: 1;
    @extend %flex-align-center;
    justify-content: space-between;

    &:hover {
      background-color: $color-grey-light-1;
    }
    &--selected {
      background: $color-primary;
      color: #fff;
    }
  }

  &__name {
    margin-left: $x-small-margin;
  }
}

.img-rounded {
  max-width: 50px;
  height: 50px;
  border-radius: 50%;
}

.steps {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  .num {
    border: 2px solid #68c06c;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: #adb5bd75;
      border-color: #adb5bd75;
    }
  }
  .active {
    background: #6aba6d;
    color: white;
  }
  .line {
    width: 12%;
    height: 3px;
    background: #69bf6d;
  }
}
.title-item {
  border: 1px solid #e2e2e2;
  background: no-repeat;
  color: #28292b;
  margin-left: 6px;
  margin-right: 6px;
}
