.search-bar {
  margin: $small-margin 0 $small-margin 0;
  width: 100%;
  @extend %flex-align-center;
  justify-content: space-between;
  border-right: unset;
  &__date-input {
    margin-left: 50px;
    padding: 0px;
  }
  &__custom-padding {
    padding: 0px;
  }
  .form-control {
    height: 40px;
    padding: 16px;
    float: none;
    background-color: #fff;
    margin-top: 10px;
  }

  &__absolute {
    position: absolute;
    right: 7%;
    margin-top: -42px !important;
    flex-direction: column;
    display: flex;
    width: auto;
    -webkit-align-items: flex-end;
    align-items: flex-end;
    -webkit-align-self: flex-end;
    align-self: flex-end;
    z-index: 100;
  }
  &__btn {
    border: none;
    text-align: inherit;
    border: 1px solid white;
    &,
    &:hover,
    &:active {
      border: none;
      background-color: transparent;
    }
  }
  .search-bar__btn.btn {
    background-color: white;
    border: 1px solid #ced4da;
    border-left: unset;
    margin-top: 10px;
    min-width: 30px !important;
    @extend %flex-align-center;
    justify-content: center;
    &:hover,
    &:active {
      background-color: transparent;
    }
  }

  &__input-group {
    justify-content: flex-start;
    .form-control {
      height: 40px;
      padding: 16px;
      width: 25vw;
    }
    margin-right: $small-margin;
  }
  &__little-input-group {
    justify-content: flex-start;
    .form-control {
      height: 40px;
      padding: 16px;
      min-width: 18vw;
    }
    margin-left: 5px;
    margin-right: 0px;
  }

  &__icon {
    font-weight: bold;
    color: black;
    cursor: pointer;
  }
  &__flex_wrap {
    display: flex;
    flex-direction: row;
    align-content: flex-start !important;
  }
}

.btn {
  min-width: 120px;
}
// .btn-warning {
//   background-color: #e9ecef;
//   border-color: #e9ecef;
//   color: gray !important;
//   &:hover {
//     background-color: #d7dadd;
//     border-color: #d7dadd;
//   }
// }
// .btn-warning.disabled {
//   background-color: #c4c4c4;
//   border-color: #c4c4c4;
// }

.btn-outline-secondary {
  border-color: #e9ecef;
  &:hover {
    background-color: #d7dadd;
    border-color: #d7dadd;
  }
}
.btn-outline-secondary.disabled {
  border-color: #c4c4c4;
  &:hover {
    border-color: #c4c4c4;
    background-color: unset;
  }
}

.btn.small {
  min-width: 30px;
  min-height: 30px;
}

select.form-control {
  padding: 0px 5px !important;
}

.custom-margin {
  margin-left: 30px;
}
