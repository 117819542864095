.menu-category {
  cursor: pointer;
  background-color: #f2f3f2;
}

.sub-category {
  cursor: pointer;
  background-color: $color-grey-light-3;
  color: #848484;
  margin: 5px;

  &__label {
    font-size: 1.1rem;
    font-weight: normal;
  }
}

.menus,
.sub-categories {
  padding: $x-small-margin;
  border-radius: 0.25rem;
  border: $border-bottom-grey;
  border-top: none;
}
.menus.list-group {
  margin: 0px 5px 5px 5px;
}

.sub-category-active {
  border-bottom: 0px !important;
  margin-bottom: 0px;
}

.menu-category,
.sub-category,
.menu-item {
  @extend %flex-align-center;
  justify-content: space-between;
  // padding: $x-small-margin;
  // box-shadow: $shadow-light-2;
  border: 1px solid #dee2e6;
}

.draggable-container:not(:last-child) {
  margin-bottom: $x-small-margin !important;
}

.menus {
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  max-height: 20rem;
  padding-left: 0;
  padding-right: 0;
  @extend %scrollable;
}

.menu-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  cursor: pointer;

  .invalid-feedback {
    flex: 1;
    font-size: 0.8rem;
  }

  &--selected {
    color: $color-primary-2;
  }

  &__label {
    @extend %flex-align-center;
    justify-content: space-between;
    font-size: 1.2rem;
    font-weight: 500;
    flex: 1;
  }

  &__index {
    @extend %flex-align-center;
  }

  &__icon {
    flex: 1;
    justify-content: "flex-end";
  }
}

.card-menu {
  &__item {
    border-radius: 10px;
    max-width: 12rem;
    min-width: 12rem;
    padding: 1.2rem;
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
    border-color: rgba(223, 225, 229, 0);
    position: relative;
    overflow: hidden !important;
    margin: 0.7rem;
    display: table;
  }

  &__price {
    color: $color-primary-1;

    font-size: 1.2rem;
    text-align: center;
  }

  &__image {
    border-radius: 10px;
    width: 7rem;
    height: 7rem;
    max-width: 11rem;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: auto;
  }

  &__sub-container {
    position: relative;
  }

  &__content {
    text-align: center;
  }

  &__content p {
    margin-bottom: 0;
    color: #939393;
    font-size: 0.8rem;
  }

  &__title {
    font-size: 1rem;
  }

  &__out-of-stock {
    height: 10px;
    font-size: 10px;
    text-align: center;
    position: absolute;
    color: white;
    transform: rotate(-45deg);
    transform-origin: 100% 0;
    display: table;
    top: -30px;
    left: -70px;
    padding: 0 48px;
    border: 5px;
    font-weight: bold;
    z-index: 2;
  }
  &__disabled {
    height: 10px;
    position: absolute;
    font-size: 10px;
    text-align: center;
    color: white;
    transform: rotate(-45deg);
    transform-origin: 100% 0;
    display: table;
    top: -30px;
    left: -70px;
    padding: 0 43px;
    border: 5px;
    font-weight: bold;
    z-index: 2;
  }
}

.card-menu-detail {
  &__item {
    border-radius: 10px;
    max-width: 16rem;
    min-width: 16rem;
    padding: 0rem;
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
    border-color: rgba(223, 225, 229, 0);
    position: relative;
    overflow: hidden !important;
    margin: 0.7rem;
    margin-left: 0rem;
    margin-bottom: 1.2rem;
    display: table;
  }

  &__price {
    color: #ef9304;
    font-size: 1.2rem;
    text-align: end;
    padding: 0.5rem;
    margin-right: 0.2rem;
    font-weight: bolder;
  }

  &__image {
    border-radius: 10px;
    width: 12rem;
    height: 12rem;
    max-width: 12rem;
    min-width: 12rem;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: auto;
  }

  &__sub-container {
    position: relative;
    padding: 0rem 3rem 1rem 3rem;
  }
  &__empty-sub-container {
    position: relative;
    padding: 1rem 2rem 1rem 2rem;
  }
  &__sub-sub-container {
    padding-left: 2.5rem;
  }

  &__content {
    text-align: center;
  }

  &__content p {
    margin-bottom: 0;
    color: #939393;
    font-size: 0.8rem;
  }

  &__title {
    font-size: 1rem;
  }

  &__subtitle {
    font-size: 0.8rem;
    font-weight: bold;
    text-align: end;
    padding-right: 2rem;
  }
}
