.list-group {
  padding: 0;
  margin: 6px;
  &-item {
    &,
    :last-child {
      border-bottom-right-radius: none;
      border-bottom-left-radius: none;
      border: none;
    }
  }

  &__item {
    @extend %flex-align-center;
    flex-wrap: wrap;
    margin-bottom: $x-small-margin;
    font-weight: $font-size-small;
  }

  &__label {
    flex: 1;
    letter-spacing: 0.02em;
    margin-bottom: $tiny-margin;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__value {
    flex: 1;
    margin-left: 20px;
  }

  &__full-value {
    flex: 6 1;
  }
}
