%medium-photo {
  height: $medium-photo;
  width: $medium-photo;
  border-radius: 50%;
}
.labels {
  color: red !important;
}
.category-photo {
  height: 4rem;
  width: 4rem;
  border-radius: 0%;
}

%flex-align-center {
  display: flex;
  align-items: center;
}

%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

%medium-padding-vertical {
  padding: $medium-margin 0;
}

%medium-padding-horizontal {
  padding: 0 $medium-margin;
}

%medium-padding {
  @extend %medium-padding-vertical;
  @extend %medium-padding-horizontal;
}

%small-padding-vertical {
  padding: $small-margin 0;
}

%small-padding-horizontal {
  padding: 0 $small-margin;
}

%small-padding {
  @extend %small-padding-vertical;
  @extend %small-padding-horizontal;
}

%x-small-padding-vertical {
  padding: $x-small-margin 0;
}

%x-small-padding-horizontal {
  padding: 0 $x-small-margin;
}

%x-small-padding {
  @extend %x-small-padding-vertical;
  @extend %x-small-padding-horizontal;
}

%default-icon-size {
  height: $small-photo;
  width: $small-photo;
}

%medium-photo {
  height: $medium-photo;
  width: $medium-photo;
}

%large-photo {
  height: $large-photo;
  width: $large-photo;
}

%x-large-photo {
  height: $x-large-photo;
  width: $x-large-photo;
}

%small-photo {
  height: $small-photo;
  width: $small-photo;
}

%x-small-photo {
  height: $x-small-photo;
  width: $x-small-photo;
}

%menu-background {
  background: url(/images/sidebarbg.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
%public-background {
  background: url(/images/publicbg.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

%scrollable {
  overflow-y: visible;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 0.5rem;
    background-color: #fff;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: $shadow-light-3;
    box-shadow: $shadow-light-3;
    border-radius: 0.4rem;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.4rem;
    -webkit-box-shadow: $shadow-light-3;
    box-shadow: $shadow-light-3;
    background-color: $color-primary;
  }
}

.scrollable-table {
  tbody {
    @extend %scrollable;
    display: block;
    max-height: 600px;
    overflow-y: auto;
  }

  & thead,
  & tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}

.small-photo {
  @extend %small-photo;
}

.large-photo {
  @extend %large-photo;
}

.x-large-photo {
  @extend %x-large-photo;
}

.medium-photo {
  @extend %medium-photo;
}

.medium-padding-horizontal {
  @extend %medium-padding-horizontal;
}

.small-padding-horizontal {
  @extend %small-padding-horizontal;
}

.small-padding {
  @extend %small-padding;
}

.breadcrumb .breadcrumb-item a {
  color: $color-link !important;
}
.breadcrumb .breadcrumb-item a {
  color: $green-link !important;
}
.link {
  color: $green-link !important;
}
.link-DRIVE {
  color: #fb8c00 !important;
}
.link-EXPRESS {
  color: $green-link !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-disabled {
  cursor: not-allowed !important;
}

.line-height-1 {
  line-height: 1;
}

.icon {
  font-size: 1.6rem;
  font-weight: bold;

  &--danger {
    color: $color-error;
  }

  &--success {
    color: $color-primary-1;
  }

  &--warning {
    color: $color-warning;
  }
}

.badgeClass {
  background-color: $color-grey-light-1;
  font-weight: 550;
  color: currentColor;
  width: $small-width;
  padding: 0.6rem;
  margin-right: $x-small-margin;
}

.img-piece {
  height: $medium-photo;
  width: $medium-photo;
}

.round {
  border-radius: 50%;
}

.border-img {
  border: $border-img !important;
}

.danger {
  background-color: $color-error;
}
.purple {
  background-color: $color-purple;
}
.info {
  background-color: $color-info;
}
.success {
  background: $gradient-green;
}
.gradient-orange {
  -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(255, 152, 0, 0.4);
  -moz-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(255, 152, 0, 0.4);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(255, 152, 0, 0.4);
  background: $gradient-orange;
}
.gradient-green {
  -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(76, 175, 80, 0.4);
  -moz-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(76, 175, 80, 0.4);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(76, 175, 80, 0.4);

  background: $gradient-green;
}
.gradient-red {
  -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(244, 67, 54, 0.4);
  -moz-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(244, 67, 54, 0.4);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(244, 67, 54, 0.4);
  background: $gradient-red;
}
.gradient-blue {
  -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(0, 188, 212, 0.4);
  -moz-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(0, 188, 212, 0.4);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(0, 188, 212, 0.4);
  background: $gradient-blue;
}
.total-container {
  border-radius: $small-border-raduis !important;

  width: $small-width-total;
  margin-top: $large-negative-margin;
  margin-left: $tiny-margin;
  padding: $small-photo;

  &--danger {
    background: $color-error;
  }
}
.card-header {
  border-radius: $small-border-raduis !important;
  color: $color-white;
  width: 90%;
  margin-left: $large-margin;
  margin-top: $small-negative-margin;
  height: 5rem;

  &--danger {
    -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(244, 67, 54, 0.4);
    -moz-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(244, 67, 54, 0.4);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(244, 67, 54, 0.4);
    background: $gradient-red;
  }
  &--success {
    -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(76, 175, 80, 0.4);
    -moz-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(76, 175, 80, 0.4);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(76, 175, 80, 0.4);
    background: $gradient-green;
  }
  &--warning {
    -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(255, 152, 0, 0.4);
    -moz-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(255, 152, 0, 0.4);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(255, 152, 0, 0.4);
    background: $gradient-orange;
  }
  &--purple {
    -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(0, 188, 212, 0.4);
    -moz-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(0, 188, 212, 0.4);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(0, 188, 212, 0.4);
    background: $gradient-blue;
  }
}
.home-icon {
  margin-right: $x-small-margin;
  fill: $color-text;
}

.home-margin {
  margin-top: $x-large-margin;
}

.bg-primary {
  background: $color-primary;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}
.notif {
  display: flex;
  justify-content: space-between;
}

.bold {
  font-weight: 600;
}
.padding_extra {
  padding: 0.75rem 1.25rem;
}
img.padding_extra {
  padding: 1.75rem 1.25rem;
}
.text_input {
  display: inline-block;
  min-width: 107px;
}

.no-margin {
  margin: 0 !important;
}

.font-size-x-small {
  font-size: $font-size-x-small;
}

.gray-text {
  color: #86898b;
  text-align: center;
}
.extrabold {
  font-weight: 900;
}
.btn-secondary {
  background-color: #b5b5b5;
  border-color: #b5b5b5;
}
