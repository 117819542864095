// COLOR
$color-primary-1: #71c476;
$color-primary-2: #4cab51;
$green-link: #28a745;
$color-logo-1: #feac22;
$color-logo-2: #e68d00;
$color-primary: linear-gradient(to left, $color-primary-1, $color-primary-2);
$logo-background-color: linear-gradient(
  to bottom,
  $color-logo-1,
  $color-logo-2
);
$color-text: #000;
$color-grey-light: rgba(245, 245, 245, 1);
$color-grey-light-1: #eeeeee;
$color-grey-light-2: rgba(179, 179, 178, 1);
$color-grey-light-3: #fcfcfc;
$background-color: #f7f7f7;
$color-link: #5049d6;
$color-success: #6c757d;
$color-error: #dc3545;
$color-warning: #fb8c00;
$color-info: #17a2b8;
$color-white: #fff;
$color-info: aqua;
$color-purple: rgb(126, 131, 209);
$gradient-orange: linear-gradient(60deg, #ffa726, #fb8c00);
$gradient-green: linear-gradient(60deg, #66bb6a, #43a047);
$gradient-red: linear-gradient(60deg, #ef5350, #e53935);
$gradient-blue: linear-gradient(60deg, #26c6da, #00acc1);

// DIMENSIONS
$side-bar-width: 19rem;
$nav-bar-left: 15rem;
$nav-bar-height: 5rem;
$small-width-total: 5.5rem;
$large-photo: 5rem;
$x-large-photo: 9rem;
$medium-photo: 3rem;
$small-photo: 2rem;
$x-small-photo: 1.5rem;
$small-margin: 2rem;
$x-small-margin: 1rem;
$tiny-margin: 0.3rem;
$medium-margin: 4rem;
$big-margin: 6rem;
$large-margin: 2rem;
$x-large-margin: 3rem;
$small-width: 8rem;
$small-negative-margin: -1.5rem;
$large-negative-margin: -2.5rem;
$small-border-raduis: 3px;

// FONTS
$font-size-x-large: 2.3rem;
$font-size-x-large: 2rem;
$font-size-medium: 1.6rem;
$font-size-small: 1.4rem;
$font-size-x-small: 0.9rem;

// BORDER
$border-img: 2px solid #dee2e6;
$white-border: 0.3rem solid #fff;
$input-group-border: 1px solid #cbcbd2;
$border-bottom-grey: 1px solid #dee2e6;

// SHADOWS

// SHADOWS
$shadow-light-1: 0.1rem 0 0.1rem 0 rgba(0, 0, 0, 0.5);
$shadow-light-2: 0 0 0.1rem rgba(0, 0, 0, 0.5);
$shadow-light-3: inset 0 0 6rem rgba(0, 0, 0, 0.3);
$shadow-light-4: 0 3rem 8rem rgba(0, 0, 0, 0.25);
$shadow-light-5: 0 3px 8px rgba(0, 0, 0, 0.25);
