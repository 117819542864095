.nav-tabs {
  background-color: $background-color;
  padding: 0;

  .tab {
    flex: 1;
    @extend %flex-align-center;
    justify-content: space-between;
    flex-wrap: wrap;

    &--active {
      background-color: #fff;
    }

    &__header {
      flex: 1;
      height: 100%;
      width: 100%;
      cursor: pointer;
      text-align: center;
      font-weight: 600;

      &,
      &:hover {
        border-color: #fff;
      }
      .nav-link {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
