.modal {
  &__header {
    @extend %flex-center;
    background: $color-primary;
    color: #fff;
  }

  &__body {
    min-height: 5rem;
    padding: $x-small-margin;
    text-align: center;
    font-size: $font-size-x-small;
  }

  &__footer {
    @extend %flex-align-center;
    justify-content: space-between;
  }

  &__title {
    font-size: 14px;
    font-weight: 600;
  }
}
