// {
//   minWidth: "240px",
//   marginLeft: "10px",
//   marginRight: "10px",
//   flex: 2,
//   border:" 1px solid #dee2e6",
//   backgroundColor: colorchange ? "#0000000d" : "white"
// }
.interruptions {
	tbody {
		tr {
			min-width: 240px;
			border: 1px solid #dee2e6;
			max-width: 48%;
			margin: 0 1%;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		td {
			border: none;
		}
		i {
			cursor: pointer !important;
			padding: 0.75rem 29px;
		}
	}
}

@media screen and (max-width: 863px) {
	.interruptions .table-striped tbody tr:nth-of-type(2n) {
		background-color: #0000000d !important;
	}
	.interruptions .table-striped tbody tr:nth-of-type(2n + 1) {
		background-color: white !important;
	}
}
.tooltipStore {
	position: absolute;
	top: 50%;
	right: 15%;
	z-index: 20;
}

.tooltip-inner {
	max-width: 200px;
	padding: 3px 8px;
	color: black;
	text-align: center;
	background-color: white !important;
	border: 1px #ef9502 solid;
	border-radius: 0.25rem;
}
iframe{
	width: 100%;
	height: 100%;
}
